import axios from 'axios'
import { Message } from 'element-ui'
import CryptoJS from 'crypto-js'

import CYRPTO from './crypto'

const service = axios.create({
  // 在请求头设置请求限制,只能在电商使用
  headers:{
    "x-key":CryptoJS.MD5('cp8p2Znv1xF4zwr7LLfoGSv8OoRvKB9X' + new Date().getTime().toString().substring(0,7)).toString() 
  },
})


  // request拦截器
service.interceptors.request.use(config => {
  // config.headers.origin = CryptoJS.MD5('cp8p2Znv1xF4zwr7LLfoGSv8OoRvKB9X').toString() + new Date().getTime().toString().substring(0,7)
    return config
  }, error => {
    Promise.reject(error)
})




service.interceptors.response.use(
  function (response) {
    const jsonData = response.data
    //判断是否有加密信息
    if(response.headers['content-type'].includes('Encrypt')){
     let  keyStr = 'WIl8Z3xKPLe7KXOrILzAhwciRDA4DxbstOK+rRvfaQU=';
     let  ivStr = 'i5mZxgcZhuUMIl/VgmsZPw==';
      let data =  CYRPTO.decrypt(jsonData,keyStr,ivStr)
       return JSON.parse(data)
    }else if(response.headers['content-type'].includes('EncryBPioneer')){
      let  keyStr = 'SJXFZ3xKPLe7KXOrILzAhwci4DRADxbstOK+r=FRVRA';
      let  ivStr = 'j3mZxgcSJXFzh/VgmsPIu==';
       let data =  CYRPTO.decrypt(jsonData,keyStr,ivStr)
        return JSON.parse(data)
    } else{
      if (jsonData.success) {
        return jsonData
      } else {
        Message({
          message: jsonData.error || '请求失败!',
          type: 'error',
          duration: 2 * 1000,
        })
        return Promise.reject('error')
      }
    }
  
  },
  function (error) {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = '请求错误(400)'
          break
        case 403:
          error.message = '拒绝访问(403)'
          break
        case 404:
          error.message = '请求出错(404)'
          break
        case 408:
          error.message = '请求超时(408)'
          break
        case 500:
          error.message = '服务器错误(500)'
          break
        case 501:
          error.message = '服务未实现(501)'
          break
        case 502:
          error.message = '网络错误(502)'
          break
        case 503:
          error.message = '服务不可用(503)'
          break
        case 504:
          error.message = '网络超时(504)'
          break
        case 505:
          error.message = 'HTTP版本不受支持(505)'
          break
        default:
          error.message = `连接出错(${error.response.status})!`
      }
    } else {
      error.message = '连接服务器失败!'
    }
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

export default service
